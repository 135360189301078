<template>
  <v-row>
    <v-col cols="12"
      v-if="icon">
      <div class="text-center"><v-icon size="96" v-text="icon"></v-icon>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-center">
        <span class="text-center text-h5 secondary--text">
          {{ title }}
        </span>
      </div>
      <div class="d-flex justify-center"
        v-if="subtitle">
        <span class="text-center text-body-1">
          {{ subtitle }}
        </span>
      </div>
    </v-col>
    <slot name="actions"></slot>
  </v-row>
</template>

<script>
export default {
  name: 'MessageTemplate',
  props: {
    iconName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    icon() {
      if (this.iconName) {
        if (this.iconName.startsWith('mdi')) {
          return this.iconName;
        }

        if (this.iconName.startsWith('osg')) {
          return `$${this.iconName}`;
        }
      }

      return null;
    },
  },
};
</script>